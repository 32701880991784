<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('角色管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['role/add']" size="small">{{$t('新增角色')}}</el-button>
          <!-- <el-button type="primary" size="small">导出EXCEL</el-button> -->
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-input :placeholder="$t('请输入角色名称')" v-model="search" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-input>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('角色ID')" prop="id">
        </el-table-column>
        <el-table-column :label="$t('角色名称')" prop="name">
        </el-table-column>
        <el-table-column :label="$t('角色权限')" prop="role_type_txt">
        </el-table-column>
        <el-table-column :label="$t('创建时间')" prop="created_at">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="getRoleDetail(scope)" v-if="user_role['role/detail']">{{$t('查看权限')}}</el-button>
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['role/edit']" size="small">{{$t('修改权限')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['role/delete']" size="small">{{$t('删除角色')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('操作角色')" :visible.sync="dialogFormVisible" width="80%">
      <el-row :gutter="20">
        <el-form :model="form" :label-position="getLable" label-width="80px" ref="form">
          <el-col :span="12">
            <el-form-item :label="$t('角色')" required prop="name"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="form.name" clearable></el-input>
            </el-form-item>
          </el-col><!-- /角色 -->
          <el-col :span="12">
            <el-form-item :label="$t('工单权限')" required>
              <el-select v-model="form.role_type" :placeholder="$t('请选择')">
                <el-option :label="$t('可看所有')" value="all"></el-option>
                <el-option :label="$t('仅可看自己部门下')" value="department"></el-option>
                <el-option :label="$t('仅可看自己')" value="mine"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- 角色类型 -->
          <el-col :span="24">
            <el-form-item :label="$t('权限')" required>
              <!--  -->
              <el-tree :data="powerData" show-checkbox node-key="url" ref="tree" highlight-current :props="defaultProps"
                :default-expanded-keys="checkedKeys" v-if="checkedKeys" :default-checked-keys="checkedKeys">
              </el-tree>
            </el-form-item>
          </el-col><!-- /权限 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addRole">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('角色详情')" :visible.sync="roleDetail" width="80%">
      <el-row :gutter="20">
        <el-form :label-position="getLable" label-width="80px">
          <el-col :span="24">
            <el-form-item :label="$t('权限')">
              <el-tree :data="powerData" ref="tree" :default-expanded-keys="checkedPow" show-checkbox node-key="url"
                highlight-current :props="defaultProps" :default-checked-keys="checkedPow">
              </el-tree>
            </el-form-item>
          </el-col><!-- /权限 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDetail= false">{{$t('关闭')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'Distribution',
  mixins: [mixin],
  data () {
    return {
      dialogFormVisible: false,
      UntreatedData: [],
      search: '',
      form: {
        id: null,
        name: '',
        role_type: ''
      },
      powerData: [],
      detailData: [],
      defaultProps: {
        children: 'route',
        label: 'name'
      },
      checkedKeys: [],
      checkedPow: [],
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      roleDetail: false,
      user_role: {}
    }
  },
  created () {
    this.getRoute()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  beforeMount () {
    this.getUserList()
  },
  methods: {
    // 获取权限列表
    getRoute () {
      this.$axios.post('role/getRoute').then((res) => {
        const Data = res.data
        if (Data.code === 0) {
          this.powerData = Data.data
          // console.log(this.powerData)
        } else { this.$message.warning(Data.message) }
      }).catch((error) => { this.$message.error(error) })
    },
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      // this.getRoute()
      this.checkedKeys = []
      this.$refs.tree && this.$refs.tree.setCheckedKeys(this.checkedKeys)
      if (isModify) {
        // console.log(item)
        this.$axios.post('role/detail', {
          id: item.row.id
        }).then((res) => {
          if (res.data.code === 0) {
            var roles = res.data.data.roles.split(',')
            this.checkedKeys = roles
            // console.log('roles', this.checkedKeys)
          }
        }).catch((error) => console.log(error))
        this.form = {
          id: item.row.id,
          name: item.row.name,
          role_type: item.row.role_type
        }
        this.dialogFormVisible = true
      } else {
        this.form.role_type = 'mine'
        this.checkedKeys = []
        this.dialogFormVisible = true
      }
    },
    // 获取角色列表
    getUserList (page) {
      let params = {
        page:page?page:this.Page.page,
        name:this.search
      }
      this.$axios.post('role/lists',params).then((res) => {
        // console.log(res)
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.error(Data.message)
      }).catch((error) => console.log(error))
    },
    // 添加/修改角色
    addRole () {
      var url, i, length, rolesUrl
      url = this.$refs.tree.getCheckedKeys()
      length = url.length
      rolesUrl = []
      for (i = 0; i < length; i++) {
        if (url[i] !== undefined) rolesUrl.push(url[i])
      }
      // console.log(rolesUrl)
      rolesUrl = rolesUrl.join(',')
      // 是否为修改操作
      if (this.isModify) {
        this.$axios.post('role/edit', {
          id: this.form.id,
          name: this.form.name,
          roles: rolesUrl,
          role_type: this.form.role_type
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.dialogFormVisible = false
            // console.log(res.data.data.roles)
            this.$message.success(res.data.message)
            this.$common.resetField('form', this)
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      } else {
        this.$axios.post('role/add', {
          name: this.form.name,
          roles: rolesUrl,
          role_type: this.form.role_type
        }).then((res) => {
          if (res.data.code === 0) {
            this.getUserList()
            this.dialogFormVisible = false
            this.$message.success(res.data.message)
            this.$common.resetField('form', this)
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }
    },
    // 删除角色
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('role/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取角色详情
    getRoleDetail (scope) {
      this.checkedPow = []
      // console.log(this.$refs.tree)
      this.$refs.tree && this.$refs.tree.setCheckedKeys(this.checkedPow)
      this.$axios.post('role/detail', {
        id: scope.row.id
      }).then((res) => {
        if (res.data.code === 0) {
          var roles = res.data.data.roles.split(',')
          // console.log('roles', roles)
          this.checkedPow = roles
          this.roleDetail = true
        }
      }).catch((error) => console.log(error))
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }
</style>
